header {
    height: 100vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ======= CTA ======== */

.cta {
    margin-top: 2.5 rem;   
    display: flex;       
    gap: 1.2rem;
    justify-content: center;
}

/* ======== HeaderSocials ====== */

.header_socials  {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    border-radius: 50%;
}

.header_socials::after {
    content: '';
    width: 1pm;
    height: 2rem;
    background: var(--color-primary);
}

.header_me {
    font-size: large;
    color: var(--color-light);
}

/*====== ME ====== */ 

.me {
    /*background: linear-gradient(var(--color-primary), transparent);*/
    width: 40rem;
    height: 40rem;
    position: absolute;
    left: calc(50% - 20rem);
    /* or "transform: translateX(-50%);" will do the same thing */
    margin-top: 4rem;
    border-radius: 50%;
    overflow: hidden;
    /*padding: 5rem 1.5rem 1.5rem 1.5rem*/
}

/*====== Scroll Down ====== */ 

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1.9rem;
}

/* ======= MEDIA QUERIES (MEDIUM DEVICES, Tablets) ========= */
@media screen and (max-width: 1024px) {
    header {
        height: 80vh;
    }

    .me {
        width: 28rem;
        height: 28rem;
        left: calc(50% - 15rem);
    }

}

/* ======= MEDIA QUERIES (SMALL DEVICES, Phones) ========= */
@media screen and (max-width: 600px) {
    header {
        height: 95vh;
    }

    .header_socials,
    .scroll_down {
        display: none;
    }
}