.container.recommendations_container {
    width:  40%;
    padding-bottom: 4rem;
}

.client_avatar {
    width: 7rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.recommendation {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    /*user-select: none;*/
    border: 1px solid transparent;
}

.recommendation:hover {
    transition: var(--transition);
    border-color:var(--color-primary-variant);
    background: transparent;
}


.client_review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-white);
}

/* ======= MEDIA QUERIES (MEDIUM DEVICES, Tablets) ========= */
@media screen and (max-width: 1024px) {
  .container.recommendations_container {
    width:60%;
  }
}

/* ======= MEDIA QUERIES (SMALL DEVICES, Phones) ========= */
@media screen and (max-width: 600px) {
    .container.recommendations_container {
        width: var(--container-width-sm);
    }
    
    .client_review {
        width: var(--container-width-sm);
    }
}