.projects_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.projects_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.projects_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.projects_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.projects_item h3 {
    margin: 1.2rem 0 1rem;
    text-align: center; /* Added this to a line text to center */
}

.projects_item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-top: 2rem;;
}

/* ======= MEDIA QUERIES (MEDIUM DEVICES, Tablets) ========= */
@media screen and (max-width: 1024px) {
    .projects_container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ======= MEDIA QUERIES (SMALL DEVICES, Phones) ========= */
@media screen and (max-width: 600px) {
    .projects_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}